.feature-content {
    position: absolute;
    width: 100%;
    left: 100%;
    transition: left 0.5s ease-in-out;
    opacity: 0;
}

.feature-content.active {
  left: 0;
  opacity: 1;
}

.feature-area {
  position: relative;
  overflow: hidden;
  height: 480px; /* Adjust this height based on your content */
}

.feature-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.feature-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: grey;
  margin: 0 5px;
}

.feature-indicator.active {
  background-color: darkgrey;
}

@media screen and (max-width: 768px) {
  .feature-area {
    font-size: 0.6rem;
  }
}

#pricing-table .columns {
    display: flex;
    flex-wrap: wrap; 
}

#use-cases {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#use-cases .column {
    display: flex;
    flex-direction: column;
}

#use-cases .card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

#use-cases .card-content {
    flex-grow: 1;
}

#pricing-table .column {
    display: flex;
    flex-direction: column;
}

#pricing-table .card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

#pricing-table .card-content {
    flex-grow: 1;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  overflow-wrap: normal;
  word-break: keep-all;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}