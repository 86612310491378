.message_thread {
    overflow-y: auto; 
    width: 100%;
    background: #FFFFFF;
    color: #EAEAEA;
    z-index: 50;
}

.view_new_messages_button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    color: white;
    border: white solid 1px;
    border-radius: 50%;     /* Makes it a circle */
    width: 30px;            /* Diameter */
    height: 30px;           /* Diameter */
    cursor: pointer;
    font-size: 1.5rem;      /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    /* Optional: Add a hover effect */
    &:hover {
        background-color: transparent;
    }
}

.text_message {
    padding: 1rem;
    position: relative;
    border-radius: 8px;
}

.bot-thinking {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 48px; /* Adjust size as needed */
}

.typing-dots {
    display: flex;
    margin-left: 10px;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    margin: 0 2px;
    /* Animation */
    animation: typing 1.5s infinite ease-in-out;
    opacity: 0; /* Start with dot invisible */
}

/* Different animation delays for the dots */
.dot:nth-child(1) { animation-delay: 0s; }
.dot:nth-child(2) { animation-delay: 0.5s; }
.dot:nth-child(3) { animation-delay: 1s; }

/* Animation Keyframes */
@keyframes typing {
    0%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

