.code-block {
    background-color: #282c34; /* Dark background */
    color: #abb2bf; /* Light text color for contrast */
    padding: 16px;
    border-radius: 10px;
    overflow-x: auto; /* For horizontal scrolling */
    font-family: 'Fira Code', monospace; /* Use a monospace font for better readability */
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  
  /* Additional styling can be added for specific language tokens, line numbers, etc. */
  