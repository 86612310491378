.ellipsis-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Adjust the number of lines */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px; /* Fixed width */
    max-height: calc(1.2em * 5); /* Adjust based on line-height and number of lines */
    white-space: normal;
}
