.color-picker-container {
    position: relative; /* Set the parent to relative positioning */
}

.color-picker {
    position: absolute; /* Absolutely position the color picker */
    z-index: 1000; /* Ensure it's above other content */
    left: 120%; /* Position it to the right of the button */
    top: 0; /* Align it with the top of the container */
    margin-left: 10px; /* Add some space between the button and the picker */
}
