.wrapper {
  padding: 4rem 5rem 2rem;
}

.wrapper .ql-container {
  min-height: 100px;
}

.submit-btn {
  margin-top: 1rem;
}

.preview-wrapper {
  margin-bottom: 1rem;
}

#preview {
  margin-top: 1rem;
  /* padding: 0.5rem; */
  /* border: 1px solid #ddd; */
}