/* Base styles */
.navbar-logo {
    height: auto; /* maintain aspect ratio */

}

.button.is-primary {
    padding: 10px 15px; /* default padding */
    font-size: 1rem; /* default font size */
}

/* Adjustments for tablet and mobile views */
@media (max-width: 768px) {
    .navbar-logo {
        width: 50px; /* smaller logo on tablet */
    }
    .navbar .button {
        padding: 5px 10px; /* smaller padding on tablet */
        font-size: 0.8rem; /* smaller font size on tablet */
    }
}

@media (max-width: 480px) {
    .navbar-logo {
        width: 40px; /* even smaller logo on mobile */
    }
    .navbar .button {
        padding: 2px 5px; /* smaller padding on mobile */
        font-size: 0.7rem; /* smaller font size on mobile */
    }
    /* Consider adding a hamburger menu for mobile */
}
