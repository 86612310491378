.progress-bar-container {
    position: relative; /* Container must be relative for absolute children positioning */
    display: flex;
    height: 20px;
    background-color: #e9ecef;
    overflow: visible; /* Ensure that children can appear outside the container */
  }
  
  .progress-segment {
    height: 100%;
    transition: width 0.3s ease;
    position: relative; /* Position relative to allow z-index stacking context */
    z-index: 1; /* Under the tooltip */
  }
  
  .progress-bar-tooltip {
    position: absolute;
    bottom: 100%; /* Position above the progress bar */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%) translateY(-8px); /* Adjust vertical positioning */
    background: #e9ecef;
    color: black;
    padding: 5px;
    border-radius: 6px;
    white-space: nowrap;
    z-index: 2; /* Above the progress segments */
    display: block; /* Make sure the tooltip is not set to display: none */
  }
  