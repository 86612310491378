.list {
  color: #4a4a4a;
  flex-direction: column;
  display: flex;
}

.list.has-hidden-images .list-item-image {
  display: none;
}

.list.has-hoverable-list-items .list-item:hover {
  background-color: #fafafa;
}

.list.has-overflow-ellipsis .list-item-content {
  min-width: 0;
  max-width: calc(var(--length)  * 1ch);
}

.list.has-overflow-ellipsis .list-item-content > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (hover: hover) {
  .list:not(.has-visible-pointer-controls) .list-item-controls {
    opacity: 0;
    visibility: hidden;
  }
}

.list-item {
  align-items: center;
  transition: background-color .125s ease-out;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .list-item:hover .list-item-controls, .list-item:focus-within .list-item-controls {
    opacity: initial;
    visibility: initial;
  }
}

.list-item:not(.box) {
  padding: .75em;
}

.list-item:not(:last-child):not(.box) {
  border-bottom: 1px solid #dbdbdb;
}

@media screen and (max-width: 768px) {
  .list:not(.has-overflow-ellipsis) .list-item {
    flex-wrap: wrap;
  }
}

.list-item-image {
  flex-shrink: 0;
  margin-right: .75em;
}

@media screen and (max-width: 768px) {
  .list-item-image {
    padding: .5rem 0;
  }
}

.list-item-content {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

@media screen and (max-width: 768px) {
  .list-item-content {
    padding: .5rem 0;
  }
}

.list-item-title {
  color: #363636;
  font-weight: 600;
}

.list-item-description {
  color: #7a7a7a;
}

.list-item-controls {
  flex-shrink: 0;
  transition: opacity .125s ease-out;
}

@media screen and (max-width: 768px) {
  .list-item-controls {
    flex-wrap: wrap;
    padding: .5rem 0;
  }
}

@media screen and (min-width: 769px), print {
  .list-item-controls {
    padding-left: .75em;
  }

  .list:not(.has-visible-pointer-controls) .list-item-controls {
    height: 100%;
    align-items: center;
    padding-right: .75em;
    display: flex;
    position: absolute;
    right: 0;
  }
}

/*# sourceMappingURL=bulma-list.css.map */
